import styled from 'styled-components';

export const Logo = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  background: ${props =>
    props.black
      ? `url('https://garanti.reeltime.no/images/logo-black.svg') no-repeat`
      : `url('https://garanti.reeltime.no/images/logo.svg') no-repeat`};
  background-size: auto 150px;
  background-position: center;
  margin: 0 auto;
`;

export const LogoSubheading = styled.h2`
  position: relative;
  font-size: 40px;
  font-weight: 400;
  color: white;
  text-align: center;
`;
