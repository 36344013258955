import styled from 'styled-components';

export const Circle = styled.div`
  padding: 80px;
  width: 600px;
  height: 600px;
  border-radius: 100%;
  background: white;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.02);
  z-index: 1000;

  @media screen and (max-width: 740px) {
    background: none;
    padding: 0;
    box-shadow: none;
    width: 100%;

    span {
      font-size: 20px !important;
    }
  }

  h1 {
    margin-top: 40px;
    text-align: center;
  }

  p {
    font-size: 20px;
    color: #333;
    text-align: center;
  }

  span {
    display: block;
    font-size: 35px;
    text-align: center;
  }

  .contact-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;

    .button {
      display: block;
      padding: 20px;
      background: black;
      text-decoration: none;
      font-size: 20px;
      font-weight: 600;
      color: white;
    }
  }
`;
