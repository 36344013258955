import styled from 'styled-components';

export const Heading = styled.h1`
  position: relative;
  padding: 20px 0;
  text-align: center;
  width: 400px;
  margin: 0 auto 30px auto;
  color: ${props => (props.white ? '#fff' : '#000')};

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  &::before {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    margin: auto;
    content: '';
    width: 50px;
    height: 7px;
    background: ${props => (props.white ? '#fff' : '#000')};
  }
  &::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    content: '';
    width: 50px;
    height: 7px;
    background: ${props => (props.white ? '#fff' : '#000')};
  }
`;
