import React, { Component } from 'react';
import styled from 'styled-components';
import './App.css';

import { Helmet } from 'react-helmet';
import ScrollAnim from 'rc-scroll-anim';
import QueueAnim from 'rc-queue-anim';

import { Page } from './components/layout/Page';
import { Logo, LogoSubheading } from './components/ui/Logo';
import { Circle } from './components/ui/Circle';
import { Heading } from './components/ui/Heading';
import { Progress } from './components/ui/Progress';

const ScrollOverPack = ScrollAnim.OverPack;
const Link = ScrollAnim.Link;
const Element = ScrollAnim.Element;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  padding: 0 0 0 40px;
  font-size: 25px;
  color: white;

  &::before {
    display: inline-block;
    position: absolute;
    left: 0px;
    content: '';
    width: 20px;
    height: 20px;
    background: #5fc98c;
    border-radius: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Audience = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('https://garanti.reeltime.no/images/audience.jpg') no-repeat;
  background-size: contain;
  background-position: center right;
`;

const Click = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('https://garanti.reeltime.no/images/click.jpg') no-repeat;
  background-size: contain;
  background-position: center right;
`;

const Realtime = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('https://garanti.reeltime.no/images/realtime.jpg') no-repeat;
  background-size: contain;
  background-position: center right;
`;

const SocialMedia = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('https://garanti.reeltime.no/images/social-media.jpg')
    no-repeat;
  background-size: contain;
  background-position: center right;
`;

const Social = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('https://garanti.reeltime.no/images/socialt.png') no-repeat;
  background-size: contain;
  background-position: center;
`;

const Mobile = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: url('https://garanti.reeltime.no/images/mobile-fb.jpg') no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 100;
`;

const MobileTextContent = styled.div`
  margin: 0 auto;
`;

const Included = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 600px;
  height: 100%;
  background: url('https://garanti.reeltime.no/images/included2.jpg') no-repeat;
  background-size: contain;
  background-position: center;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 100px;
  right: 0;
  left: 0;
  margin: auto;

  @media screen and (max-width: 800px) {
    top: 50px;
  }
`;

const Heading2 = styled.h1`
  width: 100%;
  max-width: 600px;
  padding: 0 20px;
  font-weight: 400;
  text-align: center;
  color: white;
  margin: 0 auto;

  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
`;

const ArrowDown = styled.div`
  transition: background-position 0.3s ease;
  position: relative;
  top: 50px;
  right: 0;
  left: 0;
  margin: auto;
  width: 140px;
  height: 100px;
  background: url('https://garanti.reeltime.no/images/arrow.svg') no-repeat;
  background-size: 50%;
  background-position: center bottom 30px;
  text-align: center;
  color: white;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    background-position: center bottom 20px;
  }
`;

const Row = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
  z-index: 2;
`;

const Column = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  ${props => (props.zIndex ? `z-index: ${props.zIndex}` : '')};

  @media screen and (max-width: 800px) {
    padding: 0 20px;
  }
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      sections: 13,
      sectionEnter: '',
      sectionLeave: '',
      listItems: [
        { key: 1, text: 'Annonsering i over 200 nettverk' },
        { key: 2, text: 'Flere målgrupper, annonser og' },
        { key: 3, text: 'Avansert målretting og remarketing' },
        { key: 4, text: 'Automatisk optimalisering og budsjettfordeling' },
        { key: 5, text: 'Full oversikt gjennom din egen Live' }
      ]
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setState({
        progress: 0
      });
      window.addEventListener('scroll', () => {
        let top = window.scrollY;
        if (top === 0) {
          this._setProgress(0);
        }
      });
    }
  }

  _setProgress(page) {
    if (page) {
      let { sections } = this.state;
      this.setState({
        progress: page ? (100 / sections) * page : 0
      });
    } else {
      this.setState({
        progress: 0
      });
    }
  }

  render() {
    return (
      <Page>
        <Helmet>
          <title>
            Treffsikker boligannonsering i digitale og sosiale medier - Garanti
          </title>
          <meta
            name="description"
            content="Bygget på Norges mest avanserte plattform 
          for digital markedsføring av eiendom - skreddersydd for GARANTI. Vi treffer de 
          rette kjøperne til din bolig - raskt, presist og kostnadseffektivt."
          />
          <meta property="og:image" content="/images/audience.jpg" />
          <meta
            property="og:description"
            content="Bygget på Norges mest avanserte plattform 
          for digital markedsføring av eiendom - skreddersydd for GARANTI. Vi treffer de 
          rette kjøperne til din bolig - raskt, presist og kostnadseffektivt."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://garanti.no/digital" />
        </Helmet>
        <Progress progress={this.state.progress} />
        <Link className="page1" to="page2">
          <Element className="section section-1" id="page1">
            <ScrollOverPack className="page1">
              <LogoWrapper>
                <QueueAnim
                  key="s-1"
                  type={['top', 'bottom']}
                  delay={500}
                  duration={1000}
                >
                  <Logo key="s-1-1" />
                  <LogoSubheading key="s-1-2">DIGITAL</LogoSubheading>
                  <Heading2 key="s-1-3">
                    TREFFSIKKER BOLIGANNONSERING I DIGITALE OG SOSIALE MEDIER
                  </Heading2>
                  <ArrowDown key="s-1-4">Klikk for å se mer</ArrowDown>
                </QueueAnim>
              </LogoWrapper>
            </ScrollOverPack>
          </Element>
        </Link>
        <Link className="page2" to="page3">
          <Element
            className="section section-2"
            id="page2"
            onChange={e => {
              this._setProgress(2);
            }}
          >
            <Row>
              <Column>
                <ScrollOverPack className="page2">
                  <QueueAnim key="s-2" type={['left']} duration={1000}>
                    <Circle className="hide-mobile" key="s-2-1">
                      <Heading>VÅRT DIGITALE MARKEDSFØRINGS-PRODUKT</Heading>
                      <p>
                        Bygget på Norges mest avanserte plattform for digital
                        markedsføring av eiendom - skreddersydd for GARANTI.
                      </p>
                      <p>
                        Vi treffer de rette kjøperne til din bolig - raskt,
                        presist og kostnadseffektivt.
                      </p>
                    </Circle>
                    <MobileTextContent className="show-mobile" key="s-2-2">
                      <Heading>VÅRT DIGITALE MARKEDSFØRINGS-PRODUKT</Heading>
                      <p>
                        Bygget på Norges mest avanserte plattform for digital
                        markedsføring av eiendom - skreddersydd for GARANTI.
                      </p>
                      <p>
                        Vi treffer de rette kjøperne til din bolig - raskt,
                        presist og kostnadseffektivt.
                      </p>
                    </MobileTextContent>
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column className="hide-mobile" />
            </Row>
            <div className="bg" />
          </Element>
        </Link>

        <Link className="page3" to="page4">
          <Element
            className="section section-3"
            id="page3"
            onChange={e => {
              this._setProgress(3);
            }}
          >
            <Row>
              <Column zIndex={100}>
                <ScrollOverPack className="page3">
                  <QueueAnim key="1" type={['left']} duration={1000}>
                    <div key="1" className="hide-mobile">
                      <Heading>SYNLIGHET I OVER 200 NETTVERK</Heading>
                      <p>
                        Vi sørger for at boligen din blir vist til mulige
                        kjøpere på Facebook, Instagram og Google Display Network
                        samt de største og viktigste nettsidene i landet.
                      </p>
                    </div>
                    <MobileTextContent key="2" className="show-mobile">
                      <Heading>SYNLIGHET I OVER 200 NETTVERK</Heading>
                      <p>
                        Vi sørger for at boligen din blir vist til mulige
                        kjøpere på Facebook, Instagram og Google Display Network
                        samt de største og viktigste nettsidene i landet.
                      </p>
                    </MobileTextContent>
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column className="hide-mobile">
                <ScrollOverPack className="page3">
                  <QueueAnim key="2" type={['right']} duration={1000}>
                    <Circle
                      key="1"
                      style={{
                        background:
                          "url('https://garanti.reeltime.no/images/social.jpg') no-repeat #fff",
                        backgroundSize: '80%',
                        backgroundPosition: 'center'
                      }}
                    />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column
                style={{ opacity: 0.1, position: 'absolute' }}
                className="show-mobile"
              >
                <ScrollOverPack className="page3">
                  <QueueAnim key="2" type={['right']} duration={1000}>
                    <Social key="1" />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
            </Row>
          </Element>
        </Link>

        <Link className="page4" to="page5">
          <Element
            className="section section-3"
            id="page4"
            onChange={e => {
              this._setProgress(4);
            }}
          >
            <Row>
              <Column zIndex={100}>
                <ScrollOverPack className="page4">
                  <QueueAnim key="1" type={['left']} duration={1000}>
                    <div key="1">
                      <Heading>
                        NÅ UT TIL OPP TIL 9 HØYT DEFINERTE MÅLGRUPPER
                      </Heading>
                      <p>
                        Basert på din boligs beliggenhet, størrelse, pris og en
                        rekke andre data, velges det ut målgrupper som utgjør
                        potensielle kjøpere.
                      </p>
                    </div>
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column className="hide-mobile">
                <ScrollOverPack className="page1">
                  <QueueAnim key="2" type={['right']} duration={1000}>
                    <Audience key="1" />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column
                style={{ position: 'absolute', opacity: 0.1 }}
                className="show-mobile"
              >
                <ScrollOverPack className="page1">
                  <QueueAnim
                    key="2"
                    type={['right']}
                    delay={400}
                    duration={1000}
                  >
                    <Audience key="1" />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
            </Row>
          </Element>
        </Link>

        <Link className="page5" to="page7">
          <Element
            className="section section-3"
            id="page5"
            onChange={e => {
              this._setProgress(5);
            }}
          >
            <Row>
              <Column className="hide-mobile">
                <ScrollOverPack className="page5 scroll-over-pack">
                  <QueueAnim
                    key="2"
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%'
                    }}
                    type={['bottom']}
                    delay={300}
                    duration={1000}
                  >
                    <Mobile key="1" />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column
                className="show-mobile"
                style={{ position: 'absolute', opacity: 0.1 }}
              >
                <ScrollOverPack className="page5 scroll-over-pack">
                  <QueueAnim
                    key="2"
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%'
                    }}
                    type={['bottom']}
                    delay={300}
                    duration={1000}
                  >
                    <Mobile key="1" />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column>
                <ScrollOverPack className="page5">
                  <QueueAnim key="1" type={['left']} duration={1000}>
                    <div key="1">
                      <Heading>...MED OPP TIL 27 ULIKE ANNONSER</Heading>
                      <p>
                        Det lages flere versjoner med ulike tekster, bilder og
                        annonseformater for å se hvilke som gjør det best i de
                        ulike målgruppene.
                      </p>
                    </div>
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
            </Row>
          </Element>
        </Link>

        {/*<Link className="page6" to="page7">
          <Element
            className="section section-4"
            id="page6"
            onChange={e => {
              this._setProgress(6);
            }}
          >
            <Row>
              <Column zIndex={100}>
                <ScrollOverPack className="page3">
                  <QueueAnim key="1" type={['left']} duration={1000}>
                    <div key="1" className="hide-mobile">
                      <Heading white="true">
                        TEKST OG MÅLRETTING FRA PROFESJONELLE MARKEDSFØRERE
                      </Heading>
                      <p style={{ color: 'white' }}>
                        Profesjonelle markedsførere går over og kvalitetssikrer
                        målretting, bilder og tekst på hver eneste kampanje.
                      </p>
                    </div>
                    <MobileTextContent key="2" className="show-mobile">
                      <Heading white="true">
                        TEKST OG MÅLRETTING FRA PROFESJONELLE MARKEDSFØRERE
                      </Heading>
                      <p style={{ color: '#fff' }}>
                        Profesjonelle markedsførere går over og kvalitetssikrer
                        målretting, bilder og tekst på hver eneste kampanje.
                      </p>
                    </MobileTextContent>
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column className="hide-mobile">
                <ScrollOverPack className="page3">
                  <QueueAnim key="2" type={['right']} duration={1000}>
                    <Circle
                      key="1"
                      style={{
                        background:
                          "url('https://garanti.reeltime.no/images/contentproducer.jpg') no-repeat #fff",
                        backgroundSize: '80%',
                        backgroundPosition: 'center'
                      }}
                    />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
            </Row>
          </Element>
                    </Link>*/}

        <Link className="page7" to="page9">
          <Element
            className="section section-3"
            id="page7"
            onChange={e => {
              this._setProgress(7);
            }}
          >
            <Row>
              <Column className="hide-mobile">
                <ScrollOverPack className="page3">
                  <QueueAnim key="2" type={['left']} duration={1000}>
                    <Circle
                      key="1"
                      style={{
                        background:
                          "url('https://garanti.reeltime.no/images/targeting.jpg') no-repeat #fff",
                        backgroundSize: '80%',
                        backgroundPosition: 'center'
                      }}
                    />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column
                style={{ opacity: 0.1, position: 'absolute' }}
                className="show-mobile"
              >
                <ScrollOverPack className="page3">
                  <QueueAnim key="2" type={['left']} duration={1000}>
                    <Social key="1" />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column zIndex={100}>
                <ScrollOverPack className="page7">
                  <QueueAnim key="1" type={['right']} duration={1000}>
                    <div key="1" className="hide-mobile">
                      <Heading>
                        KOMBINERT MED SMART DATADREVET MÅLRETTING
                      </Heading>
                      <p>
                        Du får ekstra målretting mot de som nylig har sett på
                        lignende boliger. Og alle som viser interesse for din
                        bolig får se annonsene flere ganger. Så brukes avanserte
                        algoritmer for å finne nye personer som har fellestrekk
                        med begge disse gruppene. Dette gjør at din bolig blir
                        sett av et stort antall mennesker som med høy
                        sannsynlighet ønsker å kjøpe akkurat ditt hjem.
                      </p>
                    </div>
                    <MobileTextContent key="2" className="show-mobile">
                      <Heading>
                        KOMBINERT MED SMART DATADREVET MÅLRETTING
                      </Heading>
                      <p>
                        Du får ekstra målretting mot de som nylig har sett på
                        lignende boliger. Og alle som viser interesse for din
                        bolig får se annonsene flere ganger. Så brukes avanserte
                        algoritmer for å finne nye personer som har fellestrekk
                        med begge disse gruppene. Dette gjør at din bolig blir
                        sett av et stort antall mennesker som med høy
                        sannsynlighet ønsker å kjøpe akkurat ditt hjem.
                      </p>
                    </MobileTextContent>
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
            </Row>
          </Element>
        </Link>

        {/*<Link className="page8" to="page9">
          <Element
            className="section section-3"
            id="page8"
            onChange={e => {
              this._setProgress(8);
            }}
          >
            <Row>
              <Column zIndex={100}>
                <ScrollOverPack className="page8">
                  <QueueAnim key="1" type={['left']} duration={1000}>
                    <div key="1" className="hide-mobile">
                      <Heading>
                        AUTOMATISK OPTIMALISERING GJENNOM HELE KAMPANJEPERIODEN
                      </Heading>
                      <p>
                        C følger hele tiden med på resultatene og passer på at
                        kun de beste målgruppene og annonsene kjøres til enhver
                        tid.
                      </p>
                    </div>
                    <MobileTextContent key="2" className="show-mobile">
                      <Heading>
                        AUTOMATISK OPTIMALISERING GJENNOM HELE KAMPANJEPERIODEN
                      </Heading>
                      <p>
                        C følger hele tiden med på resultatene og passer på at
                        kun de beste målgruppene og annonsene kjøres til enhver
                        tid.
                      </p>
                    </MobileTextContent>
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column className="hide-mobile">
                <ScrollOverPack className="page1">
                  <QueueAnim key="2" type={['right']} duration={1000}>
                    <Click key="1" />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column
                style={{ position: 'absolute', opacity: 0.1 }}
                className="show-mobile"
              >
                <ScrollOverPack className="page1">
                  <QueueAnim
                    key="2"
                    type={['right']}
                    delay={400}
                    duration={1000}
                  >
                    <Click key="1" />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
            </Row>
          </Element>
          </Link> */}

        <Link className="page9" to="page10">
          <Element
            className="section section-5"
            id="page9"
            onChange={e => {
              this._setProgress(9);
            }}
          >
            <Row>
              <Column zIndex={100}>
                <ScrollOverPack className="page9">
                  <QueueAnim key="1" type={['left']} duration={1000}>
                    <div key="1" className="hide-mobile">
                      <Heading>
                        FÅ MAKSIMALT UT AV MARKEDSFØRINGS-BUDSJETT
                      </Heading>
                      <p>
                        C sprer ditt annonsebudsjett utover de nettverkene som
                        gir best resultater og justerer fordelingen av seg selv
                        fortløpende.
                      </p>
                    </div>
                    <MobileTextContent key="2" className="show-mobile">
                      <Heading>
                        FÅ MAKSIMALT UT AV MARKEDSFØRINGS-BUDSJETT
                      </Heading>
                      <p>
                        C sprer ditt annonsebudsjett utover de nettverkene som
                        gir best resultater og justerer fordelingen av seg selv
                        fortløpende.
                      </p>
                    </MobileTextContent>
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column className="hide-mobile">
                <ScrollOverPack className="page1">
                  <QueueAnim key="2" type={['right']} duration={1000}>
                    <SocialMedia key="1" />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column
                style={{ position: 'absolute', opacity: 0.1 }}
                className="show-mobile"
              >
                <ScrollOverPack className="page1">
                  <QueueAnim
                    key="2"
                    type={['right']}
                    delay={400}
                    duration={1000}
                  >
                    <SocialMedia key="1" />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
            </Row>
          </Element>
        </Link>

        <Link className="page10" to="page11">
          <Element
            className="section section-3"
            id="page10"
            onChange={e => {
              this._setProgress(10);
            }}
          >
            <Row>
              <Column zIndex={100}>
                <ScrollOverPack className="page10">
                  <QueueAnim key="1" type={['left']} duration={1000}>
                    <div key="1" className="hide-mobile">
                      <Heading>FØLG MED PÅ RESULTATENE I SANNTID</Heading>
                      <p>
                        Med GARANTI Digital Live Rapport kan du enkelt følge med
                        på resultatene i sanntid fra din mobil, nettbrett og
                        PC/Mac.
                      </p>
                    </div>
                    <MobileTextContent key="2" className="show-mobile">
                      <Heading>FØLG MED PÅ RESULTATENE I SANNTID</Heading>
                      <p>
                        Med GARANTI Digital Live Rapport kan du enkelt følge med
                        på resultatene i sanntid fra din mobil, nettbrett og
                        PC/Mac.
                      </p>
                    </MobileTextContent>
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column className="hide-mobile">
                <ScrollOverPack className="page10">
                  <QueueAnim key="2" type={['right']} duration={1000}>
                    <Realtime key="1" />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column
                style={{ position: 'absolute', opacity: 0.1 }}
                className="show-mobile"
              >
                <ScrollOverPack className="page10">
                  <QueueAnim
                    key="2"
                    type={['right']}
                    delay={400}
                    duration={1000}
                  >
                    <Realtime key="1" />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
            </Row>
          </Element>
        </Link>

        <Link className="page11" to="page12">
          <Element
            className="section section-4"
            id="page11"
            onChange={e => {
              this._setProgress(11);
            }}
          >
            <Row>
              <Column className="hide-mobile">
                <Logo />
              </Column>
              <Column>
                <ScrollOverPack className="page11">
                  <QueueAnim interval={200} duration={1000}>
                    {this.state.listItems.map((item, index) => (
                      <ListItem key={item.key}>{item.text}</ListItem>
                    ))}
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
            </Row>
          </Element>
        </Link>

        <Link className="page12" to="page13">
          <Element
            className="section section-3"
            id="page12"
            onChange={e => {
              this._setProgress(12);
            }}
          >
            <Row>
              <Column>
                <ScrollOverPack className="page12">
                  <QueueAnim key="1" type={['left']} duration={1000}>
                    <div key="1">
                      <Heading>INKLUDERT I ALLE PAKKENE</Heading>
                    </div>
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column className="hide-mobile">
                <ScrollOverPack className="page12">
                  <QueueAnim key="2" type={['right']} duration={1000}>
                    <Included key="1" />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
              <Column
                style={{ position: 'absolute', opacity: 0.1 }}
                className="show-mobile"
              >
                <ScrollOverPack className="page12">
                  <QueueAnim
                    key="2"
                    type={['right']}
                    delay={400}
                    duration={1000}
                  >
                    <Included key="1" />
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
            </Row>
          </Element>
        </Link>
        <Link className="page13">
          <Element
            className="section section-3"
            id="page13"
            onChange={e => {
              this._setProgress(13);
            }}
          >
            <Row>
              <Column>
                <ScrollOverPack className="page13">
                  <QueueAnim key="1" type={['top']} duration={1000}>
                    <Circle key="1">
                      <Logo black />
                      <span>
                        Kontakt din megler for bestilling eller spørsmål
                      </span>
                      <div className="contact-wrapper">
                        <a
                          onClick={() => {
                            if (typeof window !== 'undefined') {
                              window.location.href = 'https://salg.garanti.no/';
                            }
                          }}
                          className="button"
                          href="#"
                          style={{ marginTop: '20px' }}
                        >
                          Kontakt megler
                        </a>
                      </div>
                    </Circle>
                  </QueueAnim>
                </ScrollOverPack>
              </Column>
            </Row>
          </Element>
        </Link>
      </Page>
    );
  }
}

export default App;
