import React, { Component } from 'react';
import styled from 'styled-components';

const StyledProgressWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`;

const StyledProgressBar = styled.div`
  transition: width 1s ease;
  position: absolute;
  left: 0;
  top: 0;
  width: ${props => `${props.progress}%`};
  height: 5px;
  background: #5fc98c;
`;

export class Progress extends Component {
  render() {
    let { progress } = this.props;
    return (
      <StyledProgressWrapper>
        <StyledProgressBar progress={progress} />
      </StyledProgressWrapper>
    );
  }
}
